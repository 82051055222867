<template>
  <b-card no-body class="p-1">
    <b-overlay
        :show="state.loading"
        rounded="sm"
    >
      <div class="demo-spacing-0 m-1" dir="rtl">
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="isActive=true">
          فیلتر
          <feather-icon icon="FilterIcon"/>
        </b-button>
        <!--<b-form-checkbox
                switch
                inline
                v-tooltip="BUY?'معاملات خرید':'معاملات فروش'"
                class="ml-3 align-middle"
                style="min-height: 24px"
                v-model="BUY"
                @change="getData(1,perPage)"
        />-->
      </div>

      <search-and-filter
          :is-active.sync="isActive"
          :options="columns"
          @filter="getData(1, perPage)"
          @reset="getData(1, perPage)"
      />

      <!--        <history-details-->
      <!--                :is-active.sync="isDetails"-->
      <!--                :order="items[1]"-->
      <!--        />-->

      <div class="relative-over-x">
        <b-table
            ref="refUserListTable"
            :items="items"
            :small="true"
            responsive
            :fields="columns"
            primary-key="id"
            show-empty
            empty-text="اطلاعاتی یافت نشد"
            @sort-changed="sort($event)"
            no-local-sort
            style="white-space: nowrap; min-height : 235px"
        >

          <template #cell(marketType)="data">
            <b-avatar
                size="22"
                class="my-50"
                :src="`/coins/${data.item.destinationCoin}.png`"
                :variant="`light-info`"
            />
            /
            <b-avatar
                size="22"
                class="my-50"
                :src="`/coins/${data.item.sourceCoin}.png`"
                :variant="`light-info`"
            />
            - {{ $coins[data.item.destinationCoin].persianName + ' / ' + $coins[data.item.sourceCoin].persianName }}
          </template>

          <template #cell(amount)="data">
                    <span :dir="BUY ? 'ltr' : 'rtl'">
                        {{
                        $toLocal(data.item.amount, $decimal[data.item.destinationCoin]) + ' ' + $coins[data.item.destinationCoin].code
                      }}
                    </span>
          </template>

          <template #cell(unitPrice)="data">
                        <span dir="rtl">
                            {{
                            $toLocal(data.item.unitPrice, $decimal[$marketType2sourceCoin(data.item.marketType)]) + ' ' + $coins[$marketType2sourceCoin(data.item.marketType)].persianName
                          }}
                        </span>
          </template>

          <template #cell(wholePrice)="data">
                        <span dir="rtl">
<!--{{(data.item.wholePrice?data.item.wholePrice.toLocaleString() : '') + ' ' + (data.item.to=='USDT'? 'تتر':'تومان')}}-->
                            {{
                            (BUY ? $toLocal(data.item.wholePrice, $decimal[data.item.sourceCoin]) : $toLocal(data.item.amount / data.item.wholePrice, $decimal[data.item.sourceCoin])) + ' ' + $coins[data.item.sourceCoin].persianName
                          }}
                        </span>
          </template>

          <template #cell(calculatedPriceInTomanMarket)="data">
                        <span dir="rtl">
<!--{{(data.item.wholePrice?data.item.wholePrice.toLocaleString() : '') + ' ' + (data.item.to=='USDT'? 'تتر':'تومان')}}-->
                            {{
                            ($toLocal(data.item.calculatedPriceInTomanMarket, 0)) + ' ' + $coins['TOMAN'].persianName
                          }}
                        </span>
          </template>

          <template #cell(wage)="data">
            <span dir="rtl">
              {{
                $toLocal(((data.item.wagePercent / 100) * (data.item.amount)), $decimal[data.item.destinationCoin])
                + ' ' + $coins[data.item.destinationCoin].code
              }}
            </span>
          </template>

          <template #cell(createdAtDateTime)="data">
                        <span dir="ltr">
                            {{ $G2J(data.item.createdAtDateTime, 'faDateTime') }}
                        </span>
          </template>

          <!--                <template #cell(details)>-->
          <!--                    <feather-icon-->
          <!--                            icon="FileTextIcon"-->
          <!--                            size="20"-->
          <!--                            class="cursor-pointer"-->
          <!--                            v-tooltip="'مشاهده جزییات معامله'"-->
          <!--                            @click="isDetails=true"-->
          <!--                    />-->
          <!--                </template>-->

        </b-table>
      </div>

      <!-- pagination -->
      <div
          class="demo-spacing-0 d-flex justify-content-between m-1"
      >
        <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            align="left"
            @input="getData(currentPage,perPage)"
        >
          <template #prev-text>
            <feather-icon
                icon="ChevronRightIcon"
                size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
                icon="ChevronRightIcon"
                size="18"
            />
          </template>
        </b-pagination>
        <div>
          <label for="perpage">تعداد در صفحه</label>
          <v-select
              id="perpage"
              v-model="perPage"
              dir="rtl"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              style="min-width: 85px"
              @input="getData(1,perPage)"
          />
        </div>
      </div>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BPagination,
  BCard,
  BButton,
  BTable,
  // BDropdown,
  // BDropdownItem,
  // BBadge,
  BAvatar,
  BOverlay,
  // BFormCheckbox
} from 'bootstrap-vue'
import SearchAndFilter from "@/layouts/components/SearchAndFilter";
import vSelect from 'vue-select';
import {marketFilterArray} from "@/libs/globalConstants";
// import HistoryDetails from "@/views/Deals/historyDetails";

export default {
  name: 'BnDeals',
  components: {
    // HistoryDetails,
    SearchAndFilter,
    BPagination,
    BCard,
    BButton,
    BTable,
    // BDropdown,
    // BDropdownItem,
    // BBadge,
    BAvatar,
    vSelect,
    BOverlay,
    // BFormCheckbox,
  },
  data: () => ({
    test: 0,
    pageLength: 3,
    dir: false,
    searchTerm: '',
    currentPage: 1,
    perPage: 10,
    rows: 20,
    sortBy: '',
    isSortDirDesc: '',
    userData: [],
    isActive: false,
    isDetails: false,
    BUY: true,
    perPageOptions: [5, 10, 20, 50, 100],
    items: [],
    columns: [
      {
        label: 'بازار',
        key: 'marketType',
        sortable: true,
        searchType: 'select',
        selectOptions: marketFilterArray
      },
      {
        label: 'مقدار',
        key: 'amount',
        sortable: true,
        searchType: 'number'
      },
      {
        label: 'قیمت واحد',
        key: 'unitPrice',
        sortable: true,
        searchType: 'number'
      },
      {
        label: 'ارزش کل',
        key: 'wholePrice',
        sortable: true,
        searchType: 'number'
      },
      {
        label: 'ارزش کل (تومانی)',
        key: 'calculatedPriceInTomanMarket',
        sortable: true,
        searchType: 'number'
      },
      {
        label: 'کارمزد معامله',
        key: 'wage',
        sortable: false,
        // searchType: 'number'
      },
      {
        label: 'درصد کارمزد معامله',
        key: 'wagePercent',
        sortable: false,
        // searchType: 'number'
      },
      {
        label: 'تاریخ معامله',
        key: 'createdAtDateTime',
        sortable: true,
        searchType: 'date'
      },
      // {
      //     label: 'جزییات',
      //     key: 'details'
      // },
    ],
  }),
  computed: {
    statusVariant() {
      const a = {
        inactive: 'danger',
        active: 'success',
      }
      return e => a[e];
    },
    statusLabel() {
      const a = {
        inactive: 'لغو شده',
        active: 'انجام شده',
      }
      return e => a[e];
    },
    coinUnit() {
      const a = {
        TOMAN: 'ریال',
        BITCOIN: 'BTC',
        BITCOIN_CASH: 'BCH',
        ETHEREUM: 'ETH',
        CLASSIC_ETHEREUM: 'ETC',
        LITE_COIN: 'LTC',
        BINANCE_COIN: 'BNB',
        EOS: 'EOS',
        STELLAR: 'XLM',
        RIPPLE: 'XRP',
        TRON: 'TRX',
        DASH: 'DASH',
        TETHER: 'USDT',
      }
      return e => a[e];
    },
  },
  methods: {

    sort(e) {
      console.log(e)

      let sort = this.$toSnakeCase(e.sortBy)
      // let sort = e.sortBy
      let sorting = e.sortDesc ? 'DESC' : 'ASC'

      this.$router.push({
        query: {
          ...this.$route.query,
          orderBy: sort,
          sorting: sorting
        }
      })
      this.getData(1, this.perPage)
    },

    async getData(page, perPage) {
      this.BUY = this.BUY ? this.$route.query.type ? this.$route.query.type === 'BUY' : true : false
      const queryParams = {
        size: perPage,
        page: page,
        type: this.BUY ? 'BUY' : 'SELL',
        ...this.$route.query
      }
      this.state.loading = true
      const res = await this.$axios('/trades/otc-trades', {params: queryParams})
      this.state.loading = false
      this.items = res.data.content
      this.currentPage = res.data.number + 1
      this.rows = res.data.totalElements
    },

  },
  mounted() {
    this.getData()
  }
}
</script>
<style lang="scss">
[v-cloak] {
  opacity: 0;
}

.nowrap {
  white-space: nowrap;
}

[dir="rtl"] .test-badge {
  font-size: 10px;
  top: -6px;
  left: -5px !important;
  min-width: 16px;
  min-height: 16px;
}
</style>
